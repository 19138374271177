
import { defineComponent } from "vue";
export default defineComponent({
  name: "FlexBoxLeft",
  props: {
    title: {
      Type: String,
      default: null,
    },
    sub: {
      Type: String,
      default: null,
    },
    logo: {
      Type: String,
      default: null,
    },
    ctaText: {
      Type: String,
      default: null,
    },
    ctaLink: {
      Type: String,
      default: null,
    },
    img: {
      Type: String,
      default: null,
    },
    imgAlt: {
      Type: String,
      default: null,
    },
    bgImg: {
      Type: String,
      default: null,
    },
    bgAlt: {
      Type: String,
      default: null,
    },
    bgColor: {
      Type: String,
      default: null,
    },
  },
});
