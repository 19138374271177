
import { defineComponent } from "vue";

export default defineComponent({
  name: "FarmComponent",
  props: {
    title: {
      Type: String,
      default: "",
    },
    subtitle: {
      Type: String,
      default: "",
    },
    subColor: {
      Type: String,
      default: "#333",
    },
    paragraph: {
      Type: String,
      default: "",
    },
    logo: {
      Type: String,
      default: null,
    },
    map: {
      Type: String,
      default: null,
    },
    images: {
      default: "",
    },
    link: {
      Type: String,
      default: "",
    },
    linktwo: {
      Type: String,
      default: null,
    },
    linktwotext: {
      Type: String,
      default: "",
    },
  },
});
