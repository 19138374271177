<template>
  <div id="ad-one">
    <div class="ad-container">
      <div class="ad-image">
        <img src="@/assets/brand/FARMacy_Seal.png" />
      </div>
      <div class="ad-text">
        <p>
          The FARMacy offers specialized nutraceutical blends that aim to
          support an individual's overall wellbeing. Our property blends contain
          various types of leafy greens, cruciferous vegetables, and medicinal
          herbs that are tailored to you.
        </p>
        <router-link to="/farmacy"
          ><button class="main-button">Learn More</button></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AdOne",
});
</script>

<style scoped lang="scss">
#ad-one {
  background: var(--green);
  width: 100%;
  height: 100%;
  display: flex;

  .ad-container {
    width: 80%;
    margin: 30px auto;
    max-width: 950px;

    img {
      max-height: 250px;
      max-width: 250px;
      width: 100%;
    }
    p {
      text-align: center;
      color: var(--white);
      font-size: 1rem; //1.5
      width: 90%;
      margin: 20px auto;
    }
  }
  @media (min-width: 800px) {
    .ad-container {
      img {
        max-width: 300px;
      }
    }
  }
}
</style>
