<template>
  <div class="sixty-forty">
    <div class="flex-left">
      <slot name="flexLeft"></slot>
    </div>
    <div class="flex-right">
      <slot name="flexRight"></slot>
    </div>
  </div>
</template>

<style lang="scss">
.sixty-forty {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas: "sfLeft" "sfRight";

  .flex-left {
    grid-area: sfLeft;
  }

  .flex-right {
    grid-area: sfRight;
  }

  @media (min-width: 900px) {
    grid-template-columns: 60% 40%;
    grid-template-rows: auto;
    grid-template-areas: "sfLeft sfRight";
  }
}
</style>
