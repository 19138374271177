import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "sf-full-width" }
const _hoisted_2 = { class: "sf-full-width-wrapper" }
const _hoisted_3 = { class: "sf-full-width-text" }
const _hoisted_4 = { class: "sf-full-grid" }
const _hoisted_5 = { class: "grid-left" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "farm-logo align-left grid-right" }
const _hoisted_9 = ["src"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "sf-img-block" }
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.title !== null)
              ? (_openBlock(), _createElementBlock("h3", {
                  key: 0,
                  class: "title align-left",
                  innerHTML: _ctx.title
                }, null, 8, _hoisted_6))
              : _createCommentVNode("", true),
            (_ctx.subtitle !== null)
              ? (_openBlock(), _createElementBlock("h4", {
                  key: 1,
                  class: "subtitle align-left",
                  style: _normalizeStyle(`color:${_ctx.subColor};`),
                  innerHTML: _ctx.subtitle
                }, null, 12, _hoisted_7))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.logo !== null)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: require(`@/assets/brand/${_ctx.logo}`)
                }, null, 8, _hoisted_9))
              : _createCommentVNode("", true)
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paragraph, (p) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: p }, [
            (p !== null)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: "align-left",
                  innerHTML: p
                }, null, 8, _hoisted_10))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_11, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (img) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: img }, [
            (_ctx.images !== null)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: require(`@/assets/images/${img}`)
                }, null, 8, _hoisted_12))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ])
    ])
  ]))
}