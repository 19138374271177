<template>
  <footer>
    <div id="foot-wrapper">
      <div id="foot-logo">
        <router-link to="/"
          ><img src="@/assets/brand/FARMacy_Logo.png"
        /></router-link>
      </div>
      <div id="foot-contact">
      <h3>Contact Us</h3>
      <p><a href="tel:260-729-2080">260-729-2080</a></p>
      <p><a href="mailto:office@farmacy.us">Office@Farmacy.US</a></p>
      </div>
      <div id="foot-nav">
        <nav>
          <ul>
            <router-link to="/"><li>Home</li></router-link>
            <!--<li>Blog</li>-->
            <router-link to="/about"><li>About</li></router-link>
            <!--<li>Press</li>-->
            <router-link to="/farmacy"><li>Explore FARMacy</li></router-link>
            <router-link to="/ecsia"><li>ECSIA</li></router-link>
            <!--<li>EGN Login</li>
            <li>Privacy Policy</li>
            <li>Terms &amp; Conditions</li>
            <li>Accessibility Statement</li>
            <li>Sitemap</li>-->
          </ul>
        </nav>
      </div>
    </div>
    <div id="copy">&copy; FARMacy {{ new Date().getFullYear() }}</div>
  </footer>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
});
</script>

<style scoped lang="scss">
footer {
  background: var(--white);
  padding: 40px 0 5px 0;
  width: 100%;
  #foot-wrapper {
    display: flex;
    flex-direction: column;

    #foot-logo {
      width: 100%;
      height: 100%;
      justify-content: center;

      img {
        width: 80%;
        height: 100%;
        max-height: 80px;
        object-fit: contain;
        -o-object-fit: contain;
      }
    }
    
    #foot-contact{
    
      a{
        color:#333;
        text-decoration:none;
        
         &:hover {
          color:var(--green);
        }
      }
    
    
    }

    #foot-nav {
      display: flex;
      justify-content: center;

      nav {
        ul {
          list-style: none;
          margin: 10px 0 20px 0;
          padding: 0;

          a {
            color: var(--black);
            text-decoration: none;
          }

          li {
            text-align: center;
            font-size: 1.2rem;
            padding: 5px;
          }
        }
      }
    }

    @media (min-width: 800px) {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      #foot-logo {
        width: 20%;
      }

      img {
        max-height: 180px;
      }

      #foot-nav {
        margin: 0 40px 0 0;
        nav {
          ul {
            display: grid;
            grid-template-columns: repeat(4, auto);
            gap: 20px;

            li {
              text-align: left;
              width: 100%;
              float: left;
            }
          }
        }
      }
    }
  }
  #copy {
    font-size: 0.7rem;
    width: 100%;
    padding: 5px 0 0 0;
    border-top: 1px inset rgba(239, 239, 239, 0.3);
  }
}
</style>
