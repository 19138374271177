
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      Type: String,
      default: null,
    },
    subtitle: {
      Type: String,
      default: null,
    },
  },
});
