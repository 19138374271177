import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "subtitle green-text" }
const _hoisted_3 = { class: "sf-two-col" }
const _hoisted_4 = { class: "flex-left" }
const _hoisted_5 = { class: "flex-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_colTitle = _resolveComponent("colTitle")!
  const _component_colSubtitle = _resolveComponent("colSubtitle")!
  const _component_flexLeft = _resolveComponent("flexLeft")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_colTitle, null, {
      default: _withCtx(() => [
        _createElementVNode("h4", _hoisted_1, [
          _renderSlot(_ctx.$slots, "title")
        ])
      ]),
      _: 3
    }),
    _createVNode(_component_colSubtitle, null, {
      default: _withCtx(() => [
        _createElementVNode("h5", _hoisted_2, [
          _renderSlot(_ctx.$slots, "subtitle")
        ])
      ]),
      _: 3
    }),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_flexLeft, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "flexLeft")
          ])
        ]),
        _: 3
      }),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "flexRight")
      ])
    ])
  ], 64))
}