<template>
  <div id="mission-wrapper">
    <div id="mission">
      <h3 class="alt-title align-right">Our Mission</h3>
      <div class="message">
        <p>
          To support a complete lifestyle encompassing mental, spiritual, and
          physical wellbeing through Food is Medicine, Education, Specialized
          Training, and Research and Development.
        </p>
        <router-link to="/about"
          ><button class="main-button">Discover</button></router-link
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#mission-wrapper {
  display: flex;
  width: 100%;
  height: auto;
  padding: 50px 0;
  background-color: #87a27a;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2387A27A'/%3E%3Cstop offset='1' stop-color='%2387A27A'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  /* background by SVGBackgrounds.com */
}
#mission {
  width: 100%;
  max-width: 1050px;
  margin: auto;
  display: flex;
  width: 100%;
  height: auto;
  background: rgba(135, 162, 122, 0.5);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white);

  .message {
    width: 60%;
    margin: auto;
  }

  button {
    margin: 20px;
  }

  @media (min-width: 900px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: left;
    padding: 0 30px;

    .message {
      padding: 0 0 0 50px;
    }

    p {
      text-align: left;
    }
    button {
      display: block;
      margin: 20px 10px;
    }
  }
}
</style>
