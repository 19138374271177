<template>
  <div class="flex-responsive">
    <img src="@/assets/brand/ECSIA_Logo.png" alt="ECSIA logo" />
    <img src="@/assets/brand/EGN.svg" alt="EGN logo" />
    <img src="@/assets/brand/Blue_Crayz.svg" alt="The Blue Crayz logo" />
    <img src="@/assets/brand/garuda-logo.svg" alt="Garuda logo" />
  </div>
</template>

<style scoped lang="scss">
.flex-responsive {
  display: grid;
  grid-template-columns: repeat(4, 20%);
  grid-auto-rows: 1fr;
  justify-content: space-around;
  align-items: center;
  margin: 30px 0;

  img {
    object-fit: contain;
    width: 100%;
    height: 20vh;
  }
}
</style>
