import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["alt"]
const _hoisted_2 = { class: "phLeft" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["href", "alt"]
const _hoisted_7 = { class: "phRight" }
const _hoisted_8 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "phTwoCol",
    style: _normalizeStyle(`background: ${_ctx.bgColor}`),
    alt: `${_ctx.bgAlt}`
  }, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.logo !== null)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "ph-logo",
            src: require(`@/assets/${_ctx.logo}`)
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        (_ctx.title !== null)
          ? (_openBlock(), _createElementBlock("h1", {
              key: 0,
              innerHTML: _ctx.title
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        (_ctx.sub !== null)
          ? (_openBlock(), _createElementBlock("h2", {
              key: 1,
              innerHTML: _ctx.sub
            }, null, 8, _hoisted_5))
          : _createCommentVNode("", true)
      ]),
      (_ctx.ctaText !== null)
        ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            href: `${_ctx.ctaLink}`,
            alt: `${_ctx.ctaLink}`
          }, null, 8, _hoisted_6))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.img !== null)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: require(`@/assets/${_ctx.img}`),
            alt: `${_ctx.imgAlt}`
          }, null, 8, _hoisted_8))
        : _createCommentVNode("", true)
    ])
  ], 12, _hoisted_1))
}