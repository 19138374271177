<template>
  <div id="hero-wrapper">
    <div id="hero-bg">
      <img src="@/assets/setup/right-img-background-full.jpg" />
    </div>
    <div id="hero">
      <img class="hero-img" src="@/assets/brand/FARMacy_Logo.png" />
      <h1>Enhancing health through nutrient-dense and enzyme-rich food</h1>
      <router-link to="/farmacy">
        <button class="main-button">Learn More</button>
      </router-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
#hero-wrapper {
  width: 100%;
  position: relative;
  height: auto;
  background: #ccc;

  #hero-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    display: flex;

    img {
      display: block;
      position: relative;
      z-index: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  #hero {
    width: 90%;
    max-width: 650px;
    height: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 50;

    .hero-img {
      width: 60%;
      text-align: center;
      max-width: 400px;
      min-width: 30px;
      margin: 40px 0 0 0;
    }

    h1 {
      font-size: calc(1rem + 6 * ((100vw - 320px) / 680));
      margin: 20px 0;
    }
  }
  @media (min-width: 500px) {
    max-height: 600px;
    align-content: center;
    align-items: center;

    #hero {
      h1 {
        font-size: 1.5rem;
      }
    }
  }

  @media (min-width: 900px) {
    #hero {
      justify-content: flex-start;
      text-align: left;
      width: 60%;
      margin: 0 10%;
    }
  }
}
</style>
