import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/farmacy",
    name: "FARMacy",
    component: () => import("@/views/FARMacy.vue"),
  },
  {
    path: "/ecsia",
    name: "ECSIA",
    component: () => import("@/views/ECSIA.vue"),
  },
  {
    path: "/food-is-medicine",
    name: "FIM",
    component: () => import("@/views/FIM.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/views/Contact.vue"),
  },
  {
    path: "/about",
    name: "About",

    component: () => import("../views/About.vue"),
  },
  {
    path: "/farmacy/greenhouse",
    name: "Greenhouse",

    component: () => import("../components/FARMacy/GreenhouseDetail.vue"),
  },
  {
    path: "/farmacy/pickup",
    name: "Pickup",

    component: () => import("../components/FARMacy/FARMacyPickupDetail.vue"),
  },
  {
    path: "/farmacy/bluecrayz",
    name: "BlueCrayz",

    component: () => import("../components/FARMacy/BlueCrayzDetail.vue"),
  },
  {
    path: "/farmacy/garudastudio",
    name: "GarudaStudio",

    component: () => import("../components/FARMacy/GarudaStudioDetail.vue"),
  },
  {
    path: "/farmacy/RDLabs",
    name: "RDLabs",

    component: () => import("../components/FARMacy/RDLabsDetail.vue"),
  },
  {
    path: "/FARMacy/retail",
    name: "RetailTours",

    component: () => import("../components/FARMacy/RetailToursDetail.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
