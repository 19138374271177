<template>
  <Header />
  <div id="wrapper">
    <router-view />
  </div>
  <Footer />
</template>

<script>
import { defineComponent } from "vue";
import Header from "@/components/global/Header.vue";
import Footer from "@/components/global/Footer.vue";

export default defineComponent({
  components: {
    Header,
    Footer,
  },
});
</script>
