<template>
  <section class="problem-solution">
    <div class="green-heading">
      <h3>The Problem:</h3>
      <h3>Food Insecurity & Chronic Disease</h3>
    </div>

    <ul>
      <li>
        <p class="icon">60%</p>
        <p class="text">
          About 60 percent of the US adult population suffers from at least one
          chronic health condition.<sup>1</sup>
        </p>
      </li>
      <li>
        <p class="icon"><fai icon="users" /></p>
        <p class="text">
          1 in 3 American adults have prediabetes. Of those with prediabetes,
          more than 84% don't know they have it.<sup>2</sup>
        </p>
      </li>
      <li>
        <p class="icon"><fai icon="chart-line" /></p>
        <p class="text">
          Diet quality is now the leading risk factor for death in the United
          States, which now surpasses tobacco use.<sup>3</sup>
        </p>
      </li>
      <li>
        <p class="icon">2X</p>
        <p class="text">
          When compared to other high-income countries, the US. spends nearly
          twice as much on health care yet has the highest rate of chronic
          diseases and the lowest life expectancy.<sup>4</sup>
        </p>
      </li>
      <li>
        <p class="icon">50%</p>
        <p class="text">
          Cardiovascular diseases, cancer, and diabetes—all of which count diet
          as a significant risk factor—account for over half of all adult
          deaths.<sup>5</sup>
        </p>
      </li>
      <li>
        <p class="icon">71%</p>
        <p class="text">
          71% of youth are ineligible to serve in the United States military due
          to obesity and other health problems.<sup>6</sup>
        </p>
      </li>
    </ul>
  </section>
  <section class="problem-solution">
    <div class="green-heading">
      <h3>The Solution:</h3>
      <h3>Formulated Nutrition</h3>
    </div>
    <p class="solution-text">
      Through FARMacy and ECSIA®, the Food is Medicine Program offers Formulated
      Nutrition, which are specialized blends consisting of leafy greens,
      cruciferous vegetables, and medicinal herbs that support the management
      and prevention of chronic disease.
    </p>
  </section>
</template>

<style lang="scss" scoped>
.problem-solution {
  ul {
    list-style: none;
    width: 80%;
    margin: 10px auto;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;

    li {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0;
      padding: 0;

      .icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 2px solid var(--green);
        background: var(--green);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px 0 0 0;
      }
    }
  }
  @media (min-width: 900px) {
    ul {
      li {
        width: 40%;
        padding: 20px;
      }
    }
  }

  .solution-text {
    max-width: 80%;
    text-align: left;
    margin: 30px auto;
  }

  @media (min-width: 1200px) {
    ul {
      li {
        width: 30%;
        text-align: justify;
      }
    }
  }
}
</style>
