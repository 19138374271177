<template>
  <div id="map">
    <div class="farm-header">
      <img src="@/assets/images/FARMacy_Conceptual_Design-2.jpg" />
    </div>
    <div class="farm-body">
      <div class="title-block">
        <h3>Inside of</h3>
        <img src="@/assets/brand/FARMacy.png" />
      </div>
      <div id="map-elements">
        <div class="map-left">
          <img src="@/assets/brand/Site_Map.png" />
        </div>
        <div class="map-right">
          <p>
            The FARMacy was developed to provide communities around the world
            with access to ECSIA&reg; nutraceutical-grade vegetables, fruits,
            and herbs while working to enhance the understanding and education
            surrounding food is medicine.
          </p>
          <router-link to="/farmacy">
            <button class="main-button">Learn More</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
#map {
  width: 100%;

  .farm-header {
    width: 100%;
    height: auto;
    max-height: 300px;
    display: flex;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      -o-object-fit: contain;
    }
  }
  .farm-body {
    padding: 0;

    .title-block {
      display: flex;
      height: 90px;
      justify-content: center;
      align-items: center;
      padding: 20px 0;

      @media (max-width: 500px) {
        flex-direction: column;
        margin: 0 0 80px 0;
      }

      h3 {
        text-align: center;
        font-size: 2rem;
        color: var(--black);
        width: fit-content;
      }

      img {
        object-fit: contain;
        -o-object-fit: contain;
        height: 90%;
        margin: 0 20px 0;
      }
    }
    #map-elements {
      display: flex;
      flex-direction: column;
      width: 75%;
      margin: 80px auto;

      .map-left,
      .map-right {
        display: flex;
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        p {
          margin: auto;
          text-align: left;
          margin: 10px 20px 40px;
        }

        img {
          object-fit: contain;
          -o-object-fit: contain;
          width: 100%;
          height: 100%;
          max-height: 230px;
        }
      }

      @media (min-width: 900px) {
        flex-direction: row;
        width: 80%;

        .map-left {
          align-items: flex-end;
          margin: 0 30px 0 0;

          img {
            max-height: 400px;
          }
        }
        .map-right {
          align-items: flex-start;
          p {
            margin: 0 0 40px 0;
          }
        }
      }
    }
  }
}
</style>
