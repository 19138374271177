
import { defineComponent } from "vue";
export default defineComponent({
  name: "FlexBoxLeft",
  props: {
    title: {
      Type: String,
      default: null,
    },
    subtitle: {
      Type: String,
      default: null,
    },
    paragraph: {
      Type: String,
      default: null,
    },
    image: {
      Type: String,
      default: null,
    },
  },
});
