import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex-wrapper site-margin" }
const _hoisted_2 = { class: "flex-left flex-image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex-right block-text" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.image !== null)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: require(`@/assets/images/${_ctx.image}`)
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.title !== null)
          ? (_openBlock(), _createElementBlock("h4", {
              key: 0,
              class: "title align-left",
              innerHTML: _ctx.title
            }, null, 8, _hoisted_5))
          : _createCommentVNode("", true),
        (_ctx.subtitle !== null)
          ? (_openBlock(), _createElementBlock("h5", {
              key: 1,
              class: "subtitle align-left",
              innerHTML: _ctx.subtitle
            }, null, 8, _hoisted_6))
          : _createCommentVNode("", true),
        (_ctx.paragraph !== null)
          ? (_openBlock(), _createElementBlock("p", {
              key: 2,
              innerHTML: _ctx.paragraph
            }, null, 8, _hoisted_7))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}